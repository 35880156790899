import * as depo from '@dazn/depo';

import ISnapshot from './Shared/Interfaces/ISnapshot';
import IState from './Shared/Interfaces/IState';
import IStores from './Shared/Interfaces/IStores';
import createAuthStore from './Stores/Auth/createAuthStore';
import createConfigStore from './Stores/Config/createConfigStore';
import createDashboardStore from './Stores/Dashboard/createDashboardStore';
import createRootStore from './Stores/Root/createRootStore';

const createState = (): IState => {
    const state = depo.createState<
        IStores,
        ISnapshot,
        depo.IObservable<ISnapshot>
    >({
        log: console.log
    });

    createRootStore(state);
    createConfigStore(state);
    createDashboardStore(state);
    createAuthStore(state);

    return state;
};

export default createState;
