// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".swagger___main___31OpV {\n    transition: background 0.2s ease-in-out;\n    background-color: #fff;\n    height: 100vh;\n}\n\n.swagger___main--hidden___ThKlJ {\n    background-color: #0c161c;\n}\n\n.swagger___spec___Zpr3x {\n    opacity: 1;\n    transition: opacity 0.5s ease-in-out;\n}\n\n.swagger___spec--hidden___2Z0Ap {\n    opacity: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"main": "swagger___main___31OpV",
	"mainHidden": "swagger___main--hidden___ThKlJ",
	"spec": "swagger___spec___Zpr3x",
	"specHidden": "swagger___spec--hidden___2Z0Ap"
};
module.exports = exports;
