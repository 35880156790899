import easydropdown from 'easydropdown';
import EasydropdownFacade from 'easydropdown/dist/Easydropdown/EasydropdownFacade';
import * as React from 'react';

import ISelectProps from './Interfaces/ISelectProps';
import * as styles from './select.css';

class Select extends React.Component<ISelectProps> {
    private environmentSelect: HTMLSelectElement | null = null;
    private easydropdown: EasydropdownFacade | null = null;

    constructor(props: ISelectProps) {
        super(props);

        this.setEnvironmentSelectRef = this.setEnvironmentSelectRef.bind(this);
    }

    public componentDidMount(): void {
        if (!this.environmentSelect) return;

        this.easydropdown = easydropdown(this.environmentSelect, {
            classNames: styles,
            behavior: {
                showPlaceholderWhenOpen:
                    this.props.showPlaceholderWhenOpen || false,
                liveUpdates: this.props.liveUpdates || false
            }
        });
    }

    public componentWillUnmount(): void {
        if (!this.easydropdown) return;

        this.easydropdown.destroy();
    }

    public render(): JSX.Element {
        return (
            <select
                value={this.props.value}
                onChange={this.props.onChange}
                ref={this.setEnvironmentSelectRef}
            >
                {this.renderOptions()}
            </select>
        );
    }

    private setEnvironmentSelectRef(element: HTMLSelectElement): void {
        this.environmentSelect = element;
    }

    private renderOptions(): JSX.Element[] {
        const { options } = this.props;

        const optionElements = options.map(({ value, label }) => (
            <option value={value} key={value}>
                {label}
            </option>
        ));

        if (this.props.placeholder) {
            optionElements.unshift(
                <option data-placeholder key={0}>
                    {this.props.placeholder}
                </option>
            );
        }

        return optionElements;
    }
}

export default Select;
