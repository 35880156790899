import Specification from '../Constants/Specification';

import Service from './Service';

class ApiSpecification {
    public apiSpecificationId: Specification = Specification.SWAGGER;
    public servicesList: Service[] = [];

    public get selectedCount(): number {
        return this.servicesList.filter((platform) => platform.isSelected)
            .length;
    }

    public get hasSelected(): boolean {
        return this.selectedCount > 0;
    }

    public get isSelected(): boolean {
        return this.selectedCount === this.servicesList.length;
    }
}

export default ApiSpecification;
