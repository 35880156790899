import { PublicClientApplication } from '@azure/msal-browser';

import config from '../config';

let msalInstance: PublicClientApplication;

export const createMsalInstance = (): PublicClientApplication => {
    if (msalInstance) {
        return msalInstance;
    }

    msalInstance = new PublicClientApplication(config.msal);

    return msalInstance;
};
