import IUiEventStreamPayload from 'dashboard/src/Shared/Interfaces/UiEventStreamPayload';
import { Observable, Subscription } from 'rxjs';

import RoutePath from '../../../../Routes/Constants/RoutePath';
import Dashboard from '../Models/Dashboard';

const subscribeToDashboardAndViewSpecEvent = (
    dashboardAndEvent$: Observable<[IUiEventStreamPayload, Dashboard]>
): Subscription =>
    dashboardAndEvent$.subscribe(([uiEvent, dashboard]) => {
        const {
            service,
            specification,
            version,
            environment
        } = dashboard.selectedService;

        if (uiEvent.router) {
            uiEvent.router.history.push({
                pathname: RoutePath.SERVICE,
                search: `?spec=${specification}&service=${service}&version=${version}&environment=${environment}`
            });
        }
    });

export default subscribeToDashboardAndViewSpecEvent;
