import * as React from 'react';

import * as styles from './Rubix.css';

const Rubix = (): JSX.Element => (
    <div className={styles.rubix}>
        <div className={styles.top} />
        <div className={styles.bottom} />
        <div className={styles.left} />
        <div className={styles.right} />
    </div>
);

export default Rubix;
