import ISpecificationGetRequest from '@shared/Interfaces/Api/Specification/ISpecificationGetRequest';
import { Observable, Subject, Subscription } from 'rxjs';

import Service from '../../../State/Stores/Config/Models/Service';

const subscribeToSpecificationStream = (
    configAndJwtChange$: Observable<[Service, string]>,
    specificationRequest$: Subject<ISpecificationGetRequest>
): Subscription =>
    configAndJwtChange$.subscribe(
        ([{ service, version, specification, environment }, jwt]) => {
            specificationRequest$.next({
                headers: {
                    Authorization: `Bearer ${jwt}`
                },
                params: {
                    service,
                    version,
                    spec: specification,
                    environment
                }
            });
        }
    );

export default subscribeToSpecificationStream;
