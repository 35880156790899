// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-1-1!../../../node_modules/postcss-loader/dist/cjs.js??ref--5-oneOf-1-2!../../Shared/Styles/typography.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".servicesList___platform-menu___3ZcKI {\n    flex: 0 0 15rem;\n    background-color: #242d34;\n    border-top: 3px solid #f7ff1a;\n}\n\n.servicesList___platform-menu-header___eD6-3 {\n    padding: 0 1rem;\n    height: calc(2.5rem + 1px);\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    color: #cfd1d2;\n}\n\n.servicesList___button___38cym {\n    transition: color 150ms;\n    font-weight: 700;\n    color: #cfd1d2;\n}\n\n.servicesList___button___38cym:hover {\n    color: #fff;\n}\n\n.servicesList___button-active___3yfJo {\n    color: #fff;\n}\n\n.servicesList___platform-menu-item___5FACn {\n    padding: 0 1rem;\n    height: 2.5rem;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    color: #fff;\n    font-size: 0.9rem;\n    text-align: right;\n    user-select: none;\n    transition: background-color 150ms;\n}\n\n.servicesList___platform-menu-item-primed___1JjgG {\n    background-color: #3d454b;\n}\n\n.servicesList___platform-menu-item___5FACn:not(.servicesList___platform-menu-item-primed___1JjgG):nth-of-type(odd) {\n    background-color: #1e272c;\n}\n", ""]);
// Exports
exports.locals = {
	"platformMenu": "servicesList___platform-menu___3ZcKI",
	"platformMenuHeader": "servicesList___platform-menu-header___eD6-3 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["smallType"] + "",
	"button": "servicesList___button___38cym",
	"buttonActive": "servicesList___button-active___3yfJo",
	"platformMenuItem": "servicesList___platform-menu-item___5FACn",
	"platformMenuItemPrimed": "servicesList___platform-menu-item-primed___1JjgG"
};
module.exports = exports;
