import IService from '@shared/Interfaces/Api/List/IListGetResponse';
import IResponse from '@shared/Interfaces/IResponse';
import { Observable, Subscription } from 'rxjs';

import createDashboardStore from '../createDashboardStore';

const subscribeToApiListResponse = (
    listResponse$: Observable<IResponse<IService>>,
    dashboardStore: ReturnType<typeof createDashboardStore>
): Subscription =>
    listResponse$.subscribe((servicesData) =>
        dashboardStore.actions.loadDashboard(servicesData)
    );

export default subscribeToApiListResponse;
