// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dashboard___root___2XpLi {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    background: #0c161c;\n}\n\n.dashboard___main___2zafQ {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: row;\n    position: relative;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "dashboard___root___2XpLi",
	"main": "dashboard___main___2zafQ"
};
module.exports = exports;
