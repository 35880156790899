import { Subject } from 'rxjs';

import Services from '../Api/Services';
import { createMsalInstance } from '../Msal/createMsalInstance';
import IUiEventsStreamPayload from '../Shared/Interfaces/UiEventStreamPayload';
import createState from '../State/createState';

import IApp from './Interfaces/IApp';

class App implements IApp {
    public state = createState();
    public services = new Services(this.state.stores.root.value);
    public uiEvents$ = new Subject<IUiEventsStreamPayload>();
    public msalInstance = createMsalInstance();
}

export default App;
