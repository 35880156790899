// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-1-1!../../../node_modules/postcss-loader/dist/cjs.js??ref--5-oneOf-1-2!../../Shared/Styles/typography.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".servicesGrid___regional-deployment-list___3h73k {\n    flex: 1 0 10rem;\n    text-align: center;\n}\n\n.servicesGrid___regional-deployment-list-header___v0I2s {\n    padding: 0 1rem;\n    height: 2.75rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-bottom: 1px solid #242d34;\n    user-select: none;\n    color: #fff;\n    transition: background-color 150ms;\n}\n\n.servicesGrid___regional-deployment-list-primed___1502I .servicesGrid___regional-deployment-list-header___v0I2s {\n    background-color: #3d454b;\n}\n\n.servicesGrid___regional-deployment-list-header-flag___2-MJW {\n    display: inline-block;\n    height: 0.9rem;\n    width: 1rem;\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: center;\n    margin-left: 0.5rem;\n}\n\n.servicesGrid___platform-deployment___24IuR {\n    padding: 0 1rem;\n    height: 2.5rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 0.9rem;\n    border-bottom: 1px solid #242d34;\n    border-right: 1px solid #242d34;\n    color: #cfd1d2;\n    cursor: pointer;\n    transition: background-color 150ms, color 150ms, border-color 150ms;\n    user-select: none;\n}\n\n.servicesGrid___platform-deployment-primed___1kIZS {\n    background-color: #e7e9e8;\n    color: #0c161c;\n    border-color: #e7e9e8;\n}\n\n.servicesGrid___platform-deployment-disabled___1cBQR {\n    pointer-events: none;\n}\n\n.servicesGrid___platform-deployment-primed___1kIZS:hover {\n    background-color: #cfd1d2;\n    border-color: #e7e9e8;\n}\n\n.servicesGrid___platform-deployment___24IuR:not(.servicesGrid___platform-deployment-primed___1kIZS):nth-child(even) {\n    background-color: #111a1f;\n}\n\n.servicesGrid___platform-deployment___24IuR:not(.servicesGrid___platform-deployment-primed___1kIZS):hover {\n    background-color: #3d454b;\n    color: #fff;\n}\n", ""]);
// Exports
exports.locals = {
	"regionalDeploymentList": "servicesGrid___regional-deployment-list___3h73k",
	"regionalDeploymentListHeader": "servicesGrid___regional-deployment-list-header___v0I2s " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["smallHeadingType"] + "",
	"regionalDeploymentListPrimed": "servicesGrid___regional-deployment-list-primed___1502I",
	"regionalDeploymentListHeaderFlag": "servicesGrid___regional-deployment-list-header-flag___2-MJW",
	"platformDeployment": "servicesGrid___platform-deployment___24IuR " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["monospace"] + "",
	"platformDeploymentPrimed": "servicesGrid___platform-deployment-primed___1kIZS",
	"platformDeploymentDisabled": "servicesGrid___platform-deployment-disabled___1cBQR"
};
module.exports = exports;
