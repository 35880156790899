import { Subscription } from 'rxjs';

import IApp from '../../App/Interfaces/IApp';
import createAuthJwtTokenStream from '../../State/Stores/Auth/Stream/createAuthJwtTokenStream';
import ApiName from '../Shared/Constants/ApiName';

import createEnvironmentAndTokenChangeStream from './Streams/createEnvironmentAndTokenChangeStream';
import createTokenChangeStream from './Streams/createTokenChangeStream';
import subscribeToDefaultEnvironment from './Subscribers/subscribeToDefaultEnvironment';
import subscribeToEnvironmentChange from './Subscribers/subscribeToEnvironmentChange';

const connectListSubscriptions = (app: IApp): Subscription[] => {
    const authJwtUpdate$ = createAuthJwtTokenStream(
        app.state.stores.auth.update$
    );

    return [
        subscribeToDefaultEnvironment(
            app.state.stores.config.value.currentEnvironment,
            createTokenChangeStream(authJwtUpdate$),
            app.services[ApiName.LIST].get.request$
        ),
        subscribeToEnvironmentChange(
            createEnvironmentAndTokenChangeStream(
                app.state.stores.config.update$,
                authJwtUpdate$
            ),
            app.services[ApiName.LIST].get.request$
        )
    ];
};

export default connectListSubscriptions;
