import HttpMethod from '@shared/Constants/HttpMethod';
import IListGetRequest from '@shared/Interfaces/Api/List/IListGetRequest';
import IService from '@shared/Interfaces/Api/List/IListGetResponse';
import IResponse from '@shared/Interfaces/IResponse';

import Service from '../Shared/Service/Service';

const createGetList = (
    url: string
): Service<IListGetRequest, IResponse<IService>> =>
    new Service<IListGetRequest, IResponse<IService>>(url, HttpMethod.GET);

export default createGetList;
