import Root from '../State/Stores/Root/Models/Root';

import createGetList from './List/createGetList';
import ApiName from './Shared/Constants/ApiName';
import IServiceMethodMap from './Shared/Interfaces/IServiceMethodMap';
import createGetSpecification from './Specification/createGetSpecification';

class Services {
    public [ApiName.SPECIFICATION]: IServiceMethodMap<
        ReturnType<typeof createGetSpecification>
    >;
    public [ApiName.LIST]: IServiceMethodMap<ReturnType<typeof createGetList>>;

    constructor({ serviceUrls }: Root) {
        this[ApiName.SPECIFICATION] = {
            get: createGetSpecification(serviceUrls[ApiName.SPECIFICATION]),
            post: null,
            put: null
        };
        this[ApiName.LIST] = {
            get: createGetList(serviceUrls[ApiName.LIST]),
            post: null,
            put: null
        };
    }
}

export default Services;
