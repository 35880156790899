import { Subscription } from 'rxjs';

import IApp from '../../../App/Interfaces/IApp';

import createLoadingStream from './Streams/createLoadingStream';
import subscribeToLoadingStream from './Subscribers/subscribeToLoadingStream';

const connectRootSubscriptions = (app: IApp): Subscription[] => [
    subscribeToLoadingStream(
        createLoadingStream(app.services),
        app.state.stores.root
    )
];

export default connectRootSubscriptions;
