import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import Auth from '../Models/Auth';

const createAuthJwtTokenStream = (
    authUpdate$: Observable<Auth>
): Observable<string> =>
    authUpdate$.pipe(
        map((data) => data.token),
        filter<string>(Boolean)
    );

export default createAuthJwtTokenStream;
