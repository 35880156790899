import { State, Store } from '@dazn/depo';
import { BehaviorSubject } from 'rxjs';

import StoreName from '../../Shared/Constants/StoreName';

import Config from './Models/Config';
import resolveConfigActions from './resolveConfigActions';

const createConfigStore = (
    state: State
): Store<Config, typeof resolveConfigActions, BehaviorSubject<Config>> =>
    state.createStore(
        {
            name: StoreName.CONFIG,
            actions: resolveConfigActions,
            model: Config,
            observable: BehaviorSubject
        },
        {
            specifications: {
                openapi: 'OpenAPI',
                swagger: 'Swagger',
                asyncapi: 'AsyncApi'
            },
            environments: {
                prod: 'Production',
                stage: 'Stage',
                test: 'Test',
                dev: 'Development'
            },
            currentEnvironment: 'prod'
        }
    );

export default createConfigStore;
