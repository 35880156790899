const extractVersionInteger = (segment: string): number =>
    typeof segment === 'undefined'
        ? -1
        : parseInt(segment.replace(/[^\d.]/g, ''));

const splitToSegments = (semVer: string): string[] => semVer.split('.');

const compareSemVers = (versionA: string, versionB: string): number => {
    const versionASegments = splitToSegments(versionA);
    const versionBSegments = splitToSegments(versionB);

    const maxSegments = Math.max(versionA.length, versionB.length);

    for (let i = 0; i < maxSegments; i++) {
        const segmentA = extractVersionInteger(versionASegments[i]);
        const segmentB = extractVersionInteger(versionBSegments[i]);

        if (segmentA > segmentB) return 1;
        if (segmentA < segmentB) return -1;
    }

    return 0;
};

const sortBySemVer = (versions: string[]): string[] =>
    versions.sort(compareSemVers);

export default sortBySemVer;
