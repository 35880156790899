// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes loading-shield___loading-bar___3-j2P {\n    0% {\n        width: 0;\n        transform: translateX(0);\n    }\n\n    50% {\n        transform: translateX(0);\n    }\n\n    75% {\n        width: 100%;\n    }\n\n    100% {\n        width: 100%;\n        transform: translateX(100%);\n    }\n}\n\n.loading-shield___loading-shield___3DKmf {\n    position: absolute;\n    top: 0;\n    left: 15rem;\n    right: 0;\n    bottom: 0;\n    overflow: hidden;\n    pointer-events: none;\n}\n\n.loading-shield___loading-shield-visible___3tnun {\n    pointer-events: all;\n}\n\n.loading-shield___loading-shield___3DKmf::before,\n.loading-shield___loading-shield___3DKmf::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n}\n\n.loading-shield___loading-shield___3DKmf::before {\n    bottom: 0;\n    right: 0;\n    background-color: #242d34;\n    opacity: 0;\n    transition: opacity 300ms;\n}\n\n.loading-shield___loading-shield___3DKmf::after {\n    content: '';\n    position: absolute;\n    width: 0;\n    height: 3px;\n    background-color: #f7ff1a;\n}\n\n.loading-shield___loading-shield-visible___3tnun::before {\n    opacity: 0.75;\n}\n\n.loading-shield___loading-shield-visible___3tnun::after {\n    animation: loading-shield___loading-bar___3-j2P 0.75s infinite ease-in-out;\n}\n", ""]);
// Exports
exports.locals = {
	"loadingShield": "loading-shield___loading-shield___3DKmf",
	"loadingShieldVisible": "loading-shield___loading-shield-visible___3tnun",
	"loadingBar": "loading-shield___loading-bar___3-j2P"
};
module.exports = exports;
