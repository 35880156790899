import * as React from 'react';

import * as styles from './button.css';
import IButtonProps from './Interfaces/IButtonProps';

const Button = (props: IButtonProps): JSX.Element => (
    <button
        className={styles.buttonPrimary}
        disabled={Boolean(props.disabled)}
        onClick={props.onClick}
    >
        {props.text || props.children}
    </button>
);

export default Button;
