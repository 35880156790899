import ApiSpecification from '../Models/ApiSpecification';

const findApiSpec = (
    apiSpecificationList: ApiSpecification[],
    apiSpecificationId: string
): ApiSpecification => {
    const matchApiSpec = apiSpecificationList.find(
        (apiSpecification) =>
            apiSpecification.apiSpecificationId === apiSpecificationId
    );

    if (!matchApiSpec)
        throw new Error(`ApiSpecification "${apiSpecificationId}" not found`);

    return matchApiSpec;
};

export default findApiSpec;
