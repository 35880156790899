import HttpMethod from '@shared/Constants/HttpMethod';
import ISpecificationGetRequest from '@shared/Interfaces/Api/Specification/ISpecificationGetRequest';
import ISpecification from '@shared/Interfaces/Api/Specification/ISpecificationGetResponse';
import IResponse from '@shared/Interfaces/IResponse';

import Service from '../Shared/Service/Service';

const createGetSpecification = (
    url: string
): Service<ISpecificationGetRequest, IResponse<ISpecification>> =>
    new Service<ISpecificationGetRequest, IResponse<ISpecification>>(
        url,
        HttpMethod.GET
    );

export default createGetSpecification;
