import * as React from 'react';
import { Route, Switch } from 'react-router';
import { AuthenticatedTemplate, MsalProvider } from '@azure/msal-react';

import App from '../App/App';
import LoadToken from '../Pages/LoadToken';
import SplashPage from '../Pages/Splash';
import DashboardPage from '../Pages/Dashboard';
import SpecificationPage from '../Pages/Specification';
import RoutePath from './Constants/RoutePath';

const Routes = (app: App): JSX.Element => {
    return (
        <>
            {/* I am not exactly sure what does React.Suspense do. What action it waits for? */}
            <React.Suspense fallback={<SplashPage />}>
                <MsalProvider instance={app.msalInstance}>
                    <LoadToken />
                    <AuthenticatedTemplate>
                        <Switch>
                            <Route path={RoutePath.SERVICE}>
                                <SpecificationPage />
                            </Route>
                            <Route>
                                <DashboardPage uiEvents$={app.uiEvents$} />
                            </Route>
                        </Switch>
                    </AuthenticatedTemplate>
                </MsalProvider>
            </React.Suspense>
        </>
    );
};

export default Routes;
