import { State, Store } from '@dazn/depo';
import { BehaviorSubject } from 'rxjs';

import StoreName from '../../Shared/Constants/StoreName';

import Root from './Models/Root';
import resolveRootActions from './resolveRootActions';

const createRootStore = (state: State): Store =>
    state.createStore<Root, typeof resolveRootActions, BehaviorSubject<Root>>({
        name: StoreName.ROOT,
        actions: resolveRootActions,
        model: Root,
        observable: BehaviorSubject
    });

export default createRootStore;
