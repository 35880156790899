import { Subscription } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';

import ApiName from '../../../Api/Shared/Constants/ApiName';
import IApp from '../../../App/Interfaces/IApp';
import UiEventType from '../../../Shared/Constants/UiEventType';

import subscribeToApiListResponse from './Subscribers/subscribeToApiListResponse';
import subscribeToDashboardAndViewSpecEvent from './Subscribers/subscribeToDashboardAndViewSpecEvent';

const connectDashboardSubscriptions = (app: IApp): Subscription[] => {
    return [
        subscribeToDashboardAndViewSpecEvent(
            app.uiEvents$.pipe(
                filter(
                    ({ type }) => UiEventType.BUTTON_CLICK_VIEW_SPEC === type
                ),
                withLatestFrom(app.state.stores.dashboard.update$)
            )
        ),
        subscribeToApiListResponse(
            app.services[ApiName.LIST].get.response$,
            app.state.stores.dashboard
        )
    ];
};

export default connectDashboardSubscriptions;
