import Specification from '../Constants/Specification';

class Service {
    public apiSpecificationId: Specification = Specification.NONE;
    public serviceId = '';
    public environment = '';
    public version = '';
    public versions: string[] = [];
    public isSelected = false;
}

export default Service;
