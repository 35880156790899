import ISpecification from '@shared/Interfaces/Api/Specification/ISpecificationGetResponse';
import IResponse from '@shared/Interfaces/IResponse';

import Config from './Models/Config';
import Service from './Models/Service';

const resolveConfigActions = (
    value: Config
): {
    setEnvironment: (environment: string) => void;
    setService: (service: Service) => void;
    setSpecification: (specification: IResponse<ISpecification>) => void;
} => ({
    setEnvironment(environment: string): void {
        value.currentEnvironment = environment;
    },
    setService(service: Service): void {
        value.currentService = service;
    },
    setSpecification(rawData: IResponse<ISpecification>): void {
        const specification = Array.isArray(rawData.data)
            ? rawData.data[0]
            : rawData.data;
        value.currentSpecification = specification;
    }
});

export default resolveConfigActions;
