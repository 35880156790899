const encodeSnakeCaseUriComponent = (input: string): string =>
    encodeURIComponent(input)
        .replace(/([A-Z])/g, '_$1')
        .replace(/^_/, '')
        .toLowerCase();

const serializeParams = (params: {
    [key: string]: string | string[] | { [key: string]: string };
}): string => {
    const queries: string[] = [];

    let queryString = '';

    for (let key in params) {
        const value = params[key];

        // Convert all query parameters to 'snake_case'

        key = encodeSnakeCaseUriComponent(key);

        if (Array.isArray(value)) {
            for (let i = 0, item; (item = value[i]); i++) {
                queries.push(key + '=' + encodeURIComponent(item));
            }
        } else if (value && typeof value === 'object') {
            for (let subKey in value) {
                const subValue = value[subKey];

                subKey = encodeSnakeCaseUriComponent(subKey);

                queries.push(
                    key + '[' + subKey + ']=' + encodeURIComponent(subValue)
                );
            }
        } else {
            queries.push(key + '=' + encodeURIComponent(value));
        }
    }

    if (queries.length) {
        queryString = queries.join('&');
    }

    return queryString;
};

export default serializeParams;
