// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".logo___logo___1tCfq path {\n    fill: #e7e9e8;\n}\n", ""]);
// Exports
exports.locals = {
	"logo": "logo___logo___1tCfq"
};
module.exports = exports;
