// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-1-1!../../../node_modules/postcss-loader/dist/cjs.js??ref--5-oneOf-1-2!../../Shared/Styles/typography.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".header___header___3SH6M {\n    background: #242d34;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n    padding: 0.75rem;\n    display: flex;\n    justify-content: space-between;\n}\n\n.header___logo___2xsZU {\n    height: 2.5rem;\n    width: 2.5rem;\n    margin-right: 1rem;\n}\n\n.header___environment-menu___2Z9gt {\n    height: 2.5rem;\n}\n\n.header___latest-version___S1dkk {\n    display: flex;\n    align-items: center;\n    flex-grow: 1;\n    justify-content: flex-end;\n    padding: 0 1rem;\n    font-size: 0.9rem;\n}\n\n.header___latest-version-label___3JzDN {\n    color: #cfd1d2;\n}\n", ""]);
// Exports
exports.locals = {
	"header": "header___header___3SH6M",
	"logo": "header___logo___2xsZU",
	"environmentMenu": "header___environment-menu___2Z9gt " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["monospace"] + "",
	"latestVersion": "header___latest-version___S1dkk " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["monospace"] + "",
	"latestVersionLabel": "header___latest-version-label___3JzDN"
};
module.exports = exports;
