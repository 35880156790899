import { DepoProvider } from '@dazn/depo-react';
import * as React from 'react';
import * as DOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import ScrollToTop from './Components/ScrollToTop';
import connectApiSubscriptions from './Api/connectApiSubscriptions';
import App from './App/App';
import Routes from './Routes/Routes';
import './Shared/Styles/global.css';
import connectStoreSubscriptions from './State/connectStoreSubscriptions';

const app = new App();

connectApiSubscriptions(app);
connectStoreSubscriptions(app);

const container = document.getElementById('app');

const AppRoutes = () => Routes(app);

DOM.render(
    <DepoProvider state={app.state}>
        <Router>
            <ScrollToTop />
            <AppRoutes />
        </Router>
    </DepoProvider>,
    container
);
