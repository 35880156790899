import { Subscription } from 'rxjs';

import IApp from '../../App/Interfaces/IApp';
import createAuthJwtTokenStream from '../../State/Stores/Auth/Stream/createAuthJwtTokenStream';
import ApiName from '../Shared/Constants/ApiName';

import crateServiceAndTokenStream from './Streams/crateServiceAndTokenStream';
import subscribeToSpecificationResponse from './Subscriptions/subscribeToSpecificationResponse';
import subscribeToSpecificationStream from './Subscriptions/subscribeToSpecificationStream';

const connectSpecificationSubscriptions = (app: IApp): Subscription[] => {
    return [
        subscribeToSpecificationStream(
            crateServiceAndTokenStream(
                app.state.stores.config.update$,
                createAuthJwtTokenStream(app.state.stores.auth.update$)
            ),
            app.services[ApiName.SPECIFICATION].get.request$
        ),
        subscribeToSpecificationResponse(
            app.services[ApiName.SPECIFICATION].get.response$,
            app.state.stores.config.actions.setSpecification
        )
    ];
};

export default connectSpecificationSubscriptions;
