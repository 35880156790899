import * as React from 'react';

import composeTokenList from '../Util/composeTokenList';

import * as styles from './logo.css';

const Logo = (props: { className: string }): JSX.Element => {
    const className = composeTokenList([
        styles.logo,
        [!!props.className, props.className]
    ]);

    return (
        <svg className={className} viewBox="0 0 100 100">
            <path
                d="M59.25,37.5l2.57-8.65l2.64,8.65H59.25z M72.07,45.06l-6.75-20.43c-0.15-0.45-0.32-0.75-0.51-0.89
            c-0.19-0.14-0.5-0.21-0.93-0.21h-3.73c-0.43,0-0.74,0.08-0.95,0.23c-0.2,0.15-0.37,0.44-0.5,0.87l-6.81,20.43
            c-0.09,0.11-0.13,0.32-0.13,0.64c0,0.5,0.3,0.74,0.9,0.74h2.8c0.26,0,0.47-0.01,0.63-0.03c0.16-0.02,0.29-0.08,0.39-0.16
            c0.1-0.08,0.18-0.21,0.26-0.38c0.07-0.17,0.15-0.4,0.24-0.68l1.06-3.57h7.68l1.09,3.51c0.09,0.3,0.17,0.54,0.26,0.71
            c0.09,0.17,0.18,0.3,0.29,0.4c0.11,0.1,0.24,0.16,0.39,0.18c0.15,0.02,0.34,0.03,0.58,0.03h2.99c0.6,0,0.9-0.25,0.9-0.74
            c0-0.15,0-0.25-0.02-0.3C72.17,45.34,72.14,45.23,72.07,45.06L72.07,45.06z M68.76,53.28H66.6c-0.56,0-0.93,0.08-1.11,0.24
            c-0.18,0.16-0.27,0.49-0.27,0.98v12.13l-6.36-12.16c-0.26-0.52-0.51-0.84-0.76-0.98c-0.25-0.14-0.68-0.21-1.3-0.21h-1.99
            c-0.56,0-0.93,0.08-1.11,0.24c-0.18,0.16-0.27,0.49-0.27,0.98v20.46c0,0.49,0.08,0.82,0.26,0.98c0.17,0.16,0.53,0.24,1.09,0.24h2.19
            c0.56,0,0.93-0.08,1.11-0.24c0.18-0.16,0.27-0.49,0.27-0.98V62.81L64.71,75c0.26,0.52,0.51,0.84,0.76,0.98
            c0.25,0.14,0.68,0.21,1.3,0.21h1.99c0.56,0,0.93-0.08,1.11-0.24c0.18-0.16,0.27-0.49,0.27-0.98V54.51c0-0.49-0.09-0.82-0.27-0.98
            C69.68,53.37,69.32,53.28,68.76,53.28L68.76,53.28z M79.43,51.35l5.55,5.55v27.95H15.01V56.89l5.54-5.54c0.82-0.82,0.82-2.15,0-2.97
            l-5.54-5.54V14.88h69.97v27.95l-5.55,5.55C78.62,49.2,78.62,50.53,79.43,51.35L79.43,51.35z M90,9.86H10v35.38l4.62,4.62L10,54.48
            v35.38h80V54.48l-4.62-4.62L90,45.24V9.86z M45.57,71.43h-9.04l9.52-13.38c0.15-0.21,0.25-0.39,0.29-0.52
            c0.04-0.13,0.06-0.28,0.06-0.45v-2.57c0-0.49-0.09-0.82-0.27-0.98c-0.18-0.16-0.55-0.24-1.11-0.24H31.27c-0.5,0-0.82,0.09-0.97,0.27
            c-0.15,0.18-0.23,0.56-0.23,1.14v1.93c0,0.58,0.08,0.96,0.23,1.14c0.15,0.18,0.47,0.27,0.97,0.27h8.48l-9.39,13.38
            c-0.24,0.3-0.36,0.6-0.36,0.9v2.64c0,0.49,0.09,0.82,0.27,0.98c0.18,0.16,0.55,0.24,1.11,0.24h14.17c0.49,0,0.82-0.09,0.97-0.27
            c0.15-0.18,0.23-0.56,0.23-1.14v-1.93c0-0.58-0.08-0.96-0.23-1.14C46.39,71.52,46.06,71.43,45.57,71.43z M41.36,37.31
            c0,0.88-0.06,1.62-0.18,2.2c-0.12,0.59-0.31,1.07-0.58,1.43c-0.27,0.36-0.62,0.62-1.06,0.77c-0.44,0.15-0.98,0.23-1.62,0.23h-2.64
            v-13.9h2.64c1.22,0,2.1,0.36,2.64,1.08c0.53,0.72,0.8,1.9,0.8,3.56C41.36,32.68,41.36,37.31,41.36,37.31z M45.96,28.01
            c-0.75-1.52-1.77-2.65-3.07-3.38c-1.3-0.73-2.93-1.09-4.9-1.09H31.4c-0.56,0-0.93,0.08-1.11,0.24c-0.18,0.16-0.27,0.49-0.27,0.98
            v20.46c0,0.49,0.09,0.82,0.27,0.98c0.18,0.16,0.55,0.24,1.11,0.24h7.39c3.49,0,5.88-1.49,7.17-4.47c0.3-0.69,0.51-1.38,0.63-2.08
            c0.12-0.7,0.18-1.58,0.18-2.65V32.9c0-1.22-0.06-2.19-0.18-2.89C46.47,29.3,46.26,28.63,45.96,28.01"
            />
        </svg>
    );
};

export default Logo;
