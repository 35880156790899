import Environment from '@shared/Constants/Environment';
import { Observable } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    pluck,
    withLatestFrom
} from 'rxjs/operators';

import Config from '../../../State/Stores/Config/Models/Config';

const createEnvironmentAndTokenChangeStream = (
    configUpdate$: Observable<Config>,
    authJwtUpdate$: Observable<string>
): Observable<[string, string]> =>
    configUpdate$.pipe(
        pluck<Config, Environment>('currentEnvironment'),
        filter<Environment>(Boolean),
        distinctUntilChanged(),
        withLatestFrom(authJwtUpdate$)
    );

export default createEnvironmentAndTokenChangeStream;
