import AsyncApiComponent, { ConfigInterface } from '@asyncapi/react-component';
import '@asyncapi/react-component/styles/default.css?raw';
import { connect } from '@dazn/depo-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import Header from '../../Components/Header';
import Swagger from '../../Components/Swagger';
import StoreName from '../../State/Shared/Constants/StoreName';
import Service from '../../State/Stores/Config/Models/Service';

import './asyncapi-custom.css?raw';
import ISpecificationPage from './Interfaces/ISpecificationPage';
import * as styles from './specification.css';

const asyncapiConfig: Partial<ConfigInterface> = {
    show: {
        sidebar: true,
        info: true,
        servers: true,
        operations: true,
        messages: true,
        schemas: true,
        errors: true
    },
    sidebar: {
        showOperations: 'byDefault'
    }
};

const mapStateToProps = (value, actions) => ({
    config: value[StoreName.CONFIG],
    setServiceAction: actions[StoreName.CONFIG].setService
});

type IProps = ReturnType<typeof mapStateToProps>;

type PropsType = RouteComponentProps & ISpecificationPage & IProps;

class SpecificationPage extends React.Component<PropsType> {
    public componentWillMount(): void {
        const { location, history } = this.props;
        const params = new URLSearchParams(location.search);
        const specification = params.get('spec');
        const service = params.get('service');
        const version = params.get('version');
        const environment = params.get('environment');

        if (specification && service && version && environment) {
            this.props.setServiceAction(
                Object.assign(new Service(), {
                    service,
                    version,
                    specification,
                    environment
                })
            );
        } else {
            history.push('/');
        }
    }

    public renderServiceSpec(): JSX.Element {
        const { currentSpecification } = this.props.config;
        if (currentSpecification.asyncapi) {
            return (
                <div className="asyncapi">
                    <AsyncApiComponent
                        schema={JSON.stringify(currentSpecification)}
                        config={asyncapiConfig}
                    />
                </div>
            );
        } else {
            return <Swagger spec={currentSpecification} />;
        }
    }

    public render(): JSX.Element {
        return (
            <div className={styles.main}>
                <Header />
                {this.renderServiceSpec()}
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(SpecificationPage));
