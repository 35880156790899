import { connect } from '@dazn/depo-react';
import classnames from 'classnames/bind';
import config from 'dashboard/src/config';
import * as React from 'react';
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css?raw';

import StoreName from '../../State/Shared/Constants/StoreName';
import IRootState from '../../State/Shared/Interfaces/IState';

import Rubix from '../Rubix';

import * as styles from './swagger.css';

interface IProps {
    spec: unknown;
}

interface IState {
    isUiReady?: boolean;
    authToken?: string;
}

const classNameBind = classnames.bind(styles);

const mapStateToProps = (value: IRootState) => ({
    [StoreName.AUTH]: value[StoreName.AUTH]
});

type PropsType = ReturnType<typeof mapStateToProps> & IProps;

class Swagger extends React.Component<PropsType> {
    public state: IState = {};
    private swaggerNodeRef = React.createRef<HTMLDivElement>();
    private SwaggerUI: SwaggerUI;

    public onComplete(): void {
        this.setState({
            isUiReady: true
        });
    }

    public componentDidMount(): void {
        this.SwaggerUI = SwaggerUI({
            domNode: this.swaggerNodeRef.current,
            presets: [
                SwaggerUI.presets.apis,
                SwaggerUI.presets.SwaggerUIStandalonePreset
            ],
            plugins: [SwaggerUI.plugins.DownloadUrl],
            // layout: "StandaloneLayout",
            onComplete: this.onComplete.bind(this),
            requestInterceptor: (request) => {
                const { authToken } = this.state;
                // Provide auth token when resolving remote $ref calls,
                // Useful for specs to reference to other services specs.
                if (authToken && request.loadSpec) {
                    request.headers.Authorization = `Bearer ${authToken}`;
                }

                return request;
            },
            responseInterceptor: (response) => {
                // At the moment there's no reliable way to only intercept remote $ref requests
                // and we might end up receiving other responses such as "Try it out" ones.
                if (response?.url?.startsWith(config.endpoints.specification)) {
                    response.body = response.body.data;
                }

                return response;
            }
        });
    }

    public componentDidUpdate(prevProps: PropsType): void {
        // Auth token might become available after component is mounted
        if (
            this.SwaggerUI &&
            prevProps?.auth?.token !== this.props?.auth?.token
        ) {
            this.setState({
                authToken: this.props?.auth?.token
            });
        }

        if (this.SwaggerUI && prevProps.spec !== this.props.spec) {
            // this is dirty undocumented hack, which I found by tracking through code on http://editor.swagger.io/
            this.SwaggerUI.specActions.updateSpec(
                JSON.stringify(this.props.spec)
            );
        }
    }

    public render(): JSX.Element {
        const className = classNameBind({
            main: true,
            mainHidden: !this.state.isUiReady
        });

        const specClassName = classNameBind({
            spec: true,
            specHidden: !this.state.isUiReady
        });

        return (
            <div className={className}>
                {!this.state.isUiReady && <Rubix />}
                <div className={specClassName}>
                    <div ref={this.swaggerNodeRef} />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Swagger);
