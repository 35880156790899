import IErrorPayload from '@shared/Interfaces/IErrorPayload';
import IRequestOptions from '@shared/Interfaces/IRequestOptions';
import IResponse from '@shared/Interfaces/IResponse';
import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import Services from '../../../../Api/Services';
import IServiceMethodMap from '../../../../Api/Shared/Interfaces/IServiceMethodMap';
import Service from '../../../../Api/Shared/Service/Service';

const createLoadingStream = (services: Services): Observable<boolean> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const requests: Array<Observable<IRequestOptions<any, any, any>>> = [];
    const responses: Array<Observable<IResponse | IErrorPayload[]>> = [];

    let totalInFlight = 0;

    for (const serviceName in services) {
        const serviceMethods: IServiceMethodMap = services[serviceName];

        for (const serviceMethod in serviceMethods) {
            if (!serviceMethods[serviceMethod]) continue;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const service: Service<IRequestOptions<any, any, any>, IResponse> =
                serviceMethods[serviceMethod];

            requests.push(service.request$);
            responses.push(service.response$);
            responses.push(service.errors$);
        }
    }

    const requests$ = merge(...requests).pipe(tap(() => totalInFlight++));

    const responses$ = merge(...responses).pipe(tap(() => totalInFlight--));

    return merge(requests$, responses$).pipe(
        map(() => totalInFlight > 0),
        distinctUntilChanged()
    );
};

export default createLoadingStream;
