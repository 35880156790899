// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".typography___base-type___ev3q5 {\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    font-size: 1rem;\n}\n\n.typography___body-type___T84kQ {\n    font-family: 'DAZNOscine', helvetica, sans-serif;\n}\n\n.typography___heading-type___7sPzs {\n    font-family: 'trim-bold', helvetica, sans-serif;\n}\n\n.typography___base-type___ev3q5 strong {\n    font-weight: 700;\n}\n\n.typography___base-type___ev3q5 em {\n    font-style: italic;\n}\n\n.typography___button-type___26TkF {\n    font-family: 'trim-bold', helvetica, sans-serif;\n    font-weight: 700;\n    font-size: 1.1rem;\n    font-style: italic;\n}\n\n.typography___small-heading-type___1viNX {\n    font-weight: 900;\n    text-transform: uppercase;\n    letter-spacing: 0.075em;\n    font-size: 0.7rem;\n}\n\n.typography___small-type___1gzYE {\n    font-weight: 400;\n    font-size: 0.9rem;\n}\n\n.typography___monospace___2Rolu {\n    letter-spacing: 0.05em;\n    font-family: 'Ubuntu Mono', monospace;\n}\n", ""]);
// Exports
exports.locals = {
	"baseType": "typography___base-type___ev3q5",
	"bodyType": "typography___body-type___T84kQ",
	"headingType": "typography___heading-type___7sPzs",
	"buttonType": "typography___button-type___26TkF typography___heading-type___7sPzs",
	"smallHeadingType": "typography___small-heading-type___1viNX typography___body-type___T84kQ",
	"smallType": "typography___small-type___1gzYE typography___body-type___T84kQ",
	"monospace": "typography___monospace___2Rolu typography___base-type___ev3q5"
};
module.exports = exports;
