/**
 * Converts a lisp, dash, snake case or pascal case string to spaced words.
 */

const capitalise = (input: string) =>
    input.charAt(0).toUpperCase() + input.slice(1);

const formatOutputCase = (input: string): string => {
    if (input.match(/[_-]/)) {
        input = input.replace(/([_-][a-z0-9])/g, ($1) =>
            $1.toUpperCase().replace(/[_-]/, ' ')
        );
    }

    return capitalise(input);
};

export default formatOutputCase;
