import * as React from 'react';

import Rubix from '../../Components/Rubix';

import * as styles from './splash.css';

class SplashPage extends React.Component {
    public render(): JSX.Element {
        return (
            <div className={styles.main}>
                <div>Authenticating ...</div>
                <Rubix />
            </div>
        );
    }
}

export default SplashPage;
