import ISpecification from '@shared/Interfaces/Api/Specification/ISpecificationGetResponse';

import ILabelMap from '../../../Shared/Interfaces/ILabelMap';

import Service from './Service';

class Config {
    public specifications: ILabelMap = {};
    public environments: ILabelMap = {};

    public currentEnvironment = '';
    public currentService: Service = new Service();
    public currentSpecification: ISpecification = { info: { version: '' } };

    public get specificationsCount(): number {
        return Object.keys(this.specifications).length;
    }

    public get hasSpecification(): boolean {
        return Boolean(
            this.currentSpecification &&
                this.currentSpecification.info &&
                this.currentSpecification.info.version
        );
    }

    constructor() {
        Object.seal(this);
    }
}

export default Config;
