import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import Config from '../../../State/Stores/Config/Models/Config';
import Service from '../../../State/Stores/Config/Models/Service';

const crateServiceAndTokenStream = (
    configUpdate$: Observable<Config>,
    tokenUpdate$: Observable<string>
): Observable<[Service, string]> =>
    combineLatest([configUpdate$, tokenUpdate$]).pipe(
        map(
            ([config, token]: [Config, string]) =>
                [config.currentService, token] as [Service, string]
        ), // Explicitly cast to [Service, string]
        filter(([currentService, token]) =>
            Boolean(
                currentService.service &&
                    currentService.specification &&
                    currentService.version &&
                    currentService.environment &&
                    token
            )
        ),
        distinctUntilChanged()
    );

export default crateServiceAndTokenStream;
