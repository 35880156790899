import Environment from '@shared/Constants/Environment';
import ErrorType from '@shared/Constants/ErrorType';

import RootStatus from './Constants/RootStatus';
import Root from './Models/Root';

const resolveRootActions = (
    value: Root
): {
    changeEnvironment: (environment: Environment) => void;
    error: (errorType: ErrorType) => void;
    setStatus: (status: RootStatus) => void;
} => ({
    changeEnvironment(environment: Environment): void {
        value.Environment = environment;
    },

    error(errorType: ErrorType): void {
        value.errorType = errorType;
    },

    setStatus(status: RootStatus): void {
        value.status = status;
    }
});

export default resolveRootActions;
