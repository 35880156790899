// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-1-1!../../../node_modules/postcss-loader/dist/cjs.js??ref--5-oneOf-1-2!../../Shared/Styles/typography.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, "@keyframes selectionBar___warning-light___16TMk {\n    0%,\n    100% {\n        opacity: 0;\n    }\n\n    50% {\n        opacity: 1;\n    }\n}\n\n.selectionBar___deployment-bar___3IACd {\n    position: fixed;\n    display: flex;\n    justify-content: space-between;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    background: #242d34;\n    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);\n    padding: 1rem;\n    transform: translateY(100%);\n    transition: transform 200ms ease-in-out;\n}\n\n.selectionBar___deployment-bar-shown___3HPI7 {\n    transform: translateY(0);\n}\n\n.selectionBar___deployment-bar-menu___2siQk {\n    display: flex;\n    align-items: center;\n}\n\n.selectionBar___deployment-bar-menu___2siQk:first-child {\n    align-items: flex-start;\n}\n\n.selectionBar___deployment-bar-menu___2siQk:not(:first-child) > * {\n    margin-left: 1rem;\n}\n\n.selectionBar___deployment-bar-heading___Sriba {\n}\n\n.selectionBar___deployment-meta___iedHm {\n    border-top: 1px solid #3d454b;\n    border-left: 1px solid #3d454b;\n}\n\n.selectionBar___deployment-meta-data___17e89 {\n    min-width: 7rem;\n    border-right: 1px solid #3d454b;\n    border-bottom: 1px solid #3d454b;\n    padding: 0.2rem;\n    text-align: right;\n}\n\n.selectionBar___deployment-meta-heading___27rWm {\n    min-width: 7rem;\n    border-right: 1px solid #3d454b;\n    border-bottom: 1px solid #3d454b;\n    padding: 0.2rem;\n    text-align: right;\n}\n\n.selectionBar___deployment-meta-heading___27rWm {\n    color: #606a72;\n    font-weight: 700;\n}\n\n.selectionBar___deployment-meta-data___17e89 {\n    font-size: 0.8rem;\n}\n\n.selectionBar___deployment-meta-data-warning___s25hn {\n    color: #ee495c;\n    animation: selectionBar___warning-light___16TMk 0.8s infinite;\n}\n", ""]);
// Exports
exports.locals = {
	"deploymentBar": "selectionBar___deployment-bar___3IACd",
	"deploymentBarShown": "selectionBar___deployment-bar-shown___3HPI7",
	"deploymentBarMenu": "selectionBar___deployment-bar-menu___2siQk",
	"deploymentBarHeading": "selectionBar___deployment-bar-heading___Sriba " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["smallHeadingType"] + "",
	"deploymentMeta": "selectionBar___deployment-meta___iedHm",
	"deploymentMetaData": "selectionBar___deployment-meta-data___17e89 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["monospace"] + "",
	"deploymentMetaHeading": "selectionBar___deployment-meta-heading___27rWm " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["smallHeadingType"] + "",
	"deploymentMetaDataWarning": "selectionBar___deployment-meta-data-warning___s25hn",
	"warningLight": "selectionBar___warning-light___16TMk"
};
module.exports = exports;
