// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-1-1!../../../node_modules/postcss-loader/dist/cjs.js??ref--5-oneOf-1-2!../../Shared/Styles/typography.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".button___button___1hPuQ {\n    background: transparent;\n    position: relative;\n    border: none;\n    outline: none;\n    border-radius: 0;\n    cursor: pointer;\n    -webkit-appearance: none;\n}\n\n.button___button-primary___3MxNK {\n    background: #f7ff1a;\n    padding: 0.75rem 1rem;\n    min-width: 10rem;\n    color: #0c161c;\n    text-transform: uppercase;\n    transition: background-color 100ms;\n}\n\n.button___button-primary___3MxNK:hover {\n    background: #ced100;\n}\n\n.button___button-primary___3MxNK:disabled {\n    background: #ced100;\n}\n\n.button___button-primary___3MxNK:disabled {\n    cursor: not-allowed;\n}\n", ""]);
// Exports
exports.locals = {
	"button": "button___button___1hPuQ",
	"buttonPrimary": "button___button-primary___3MxNK button___button___1hPuQ " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["buttonType"] + ""
};
module.exports = exports;
