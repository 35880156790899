import { connect } from '@dazn/depo-react';
import UiEventType from 'dashboard/src/Shared/Constants/UiEventType';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Subject } from 'rxjs';

import IUiEventStreamPayload from '../../Shared/Interfaces/UiEventStreamPayload';
import StoreName from '../../State/Shared/Constants/StoreName';
import Button from '../Button/Button';
import Select from '../Select/Select';
import composeTokenList from '../Util/composeTokenList';

import * as styles from './selectionBar.css';

const mapStateToProps = (value, actions) => ({
    dashboard: value[StoreName.DASHBOARD],
    config: value[StoreName.CONFIG],
    root: value[StoreName.ROOT],
    setVersionAction: actions[StoreName.DASHBOARD].setVersion
});

type IProps = ReturnType<typeof mapStateToProps>;
type PropsType = IProps &
    RouteComponentProps & {
        uiEvents$: Subject<IUiEventStreamPayload>;
    };
class SelectionBar extends React.Component<PropsType> {
    constructor(props) {
        super(props);

        this.handleSelectVersion = this.handleSelectVersion.bind(this);
        this.handleViewSpecButtonClick = this.handleViewSpecButtonClick.bind(
            this
        );
    }

    public render(): JSX.Element {
        const deploymentBarClassName = composeTokenList([
            styles.deploymentBar,
            [this.props.dashboard.hasSelected, styles.deploymentBarShown]
        ]);

        const isViewSpecButtonDisabled =
            this.props.root.isLoading &&
            Boolean(this.props.dashboard.selectedService.version);

        return (
            <div className={deploymentBarClassName}>
                <h4 className={styles.deploymentBarHeading}>
                    Preparing API Spec
                </h4>

                <div className={styles.deploymentBarMenu}>
                    {this.renderMetaData()}
                    <Select
                        options={this.props.dashboard.serviceVersions}
                        value={this.props.dashboard.selectedService.version}
                        onChange={this.handleSelectVersion}
                        liveUpdates
                    />
                    <Button
                        text="View Spec"
                        disabled={isViewSpecButtonDisabled}
                        onClick={this.handleViewSpecButtonClick}
                    />
                </div>
            </div>
        );
    }

    private renderMetaData(): JSX.Element {
        const {
            specification: apiSpecificationId,
            service: serviceId
        } = this.props.dashboard.selectedService;

        return (
            <table className={styles.deploymentMeta}>
                <tbody>
                    <tr>
                        <th className={styles.deploymentMetaHeading}>API</th>
                        <th className={styles.deploymentMetaHeading}>SPEC</th>
                    </tr>
                    <tr>
                        <td className={styles.deploymentMetaData}>
                            {this.props.dashboard.services[serviceId]}
                        </td>
                        <td className={styles.deploymentMetaData}>
                            {
                                this.props.config.specifications[
                                    apiSpecificationId
                                ]
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    private handleSelectVersion(e: React.ChangeEvent<HTMLSelectElement>): void {
        const { value: nextVersion } = e.target;
        this.props.setVersionAction(nextVersion);
    }

    private handleViewSpecButtonClick(): void {
        this.props.uiEvents$.next({
            type: UiEventType.BUTTON_CLICK_VIEW_SPEC,
            router: {
                location: this.props.location,
                history: this.props.history,
                match: this.props.match
            }
        });
    }
}

export default connect(mapStateToProps)(withRouter(SelectionBar));
