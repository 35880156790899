import {
    UnauthenticatedTemplate,
    useMsalAuthentication,
    useMsal,
    useAccount
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import * as React from 'react';
import { connect } from '@dazn/depo-react';

import StoreName from '../../State/Shared/Constants/StoreName';
import Auth from '../../State/Stores/Auth/Models/Auth';
import config from '../../config';

const loginRequest = {
    scopes: [`${config.msal.auth.clientId}/.default`],
    authority: config.msal.auth.authority
};

const mapStateToProps = (value, actions) => ({
    setTokenAction: actions[StoreName.AUTH].setToken,
    setAccountAction: actions[StoreName.AUTH].setAccount,
    auth: value[StoreName.AUTH]
});

interface ILoadTokenPage {
    [StoreName.AUTH]: Auth;
}

type IProps = ReturnType<typeof mapStateToProps>;

type PropsType = ILoadTokenPage & IProps;

const LoadTokenPage = (props: PropsType): JSX.Element => {
    useMsalAuthentication(InteractionType.Redirect);

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    React.useEffect(() => {
        if (account && account != null) {
            props.setAccountAction(account);

            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account
                })
                .then((response) => {
                    props.setTokenAction(response.accessToken);
                })
                .catch((e) => {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account
                    });

                    console.log(e);
                });
        }
    }, [account, instance, props]);

    return (
        <>
            <UnauthenticatedTemplate>Logging in...</UnauthenticatedTemplate>
        </>
    );
};

export default connect(mapStateToProps)(LoadTokenPage);
