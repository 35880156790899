import ErrorType from '@shared/Constants/ErrorType';
import HttpMethod from '@shared/Constants/HttpMethod';
import IErrorPayload from '@shared/Interfaces/IErrorPayload';
import IRequestOptions from '@shared/Interfaces/IRequestOptions';
import IResponse from '@shared/Interfaces/IResponse';
import { Subject } from 'rxjs';

import serialiseParams from '../Util/serialiseParams';

class Service<
    /* eslint-disable @typescript-eslint/no-explicit-any */
    TRequest extends IRequestOptions<any, any, any>,
    TResponse extends IResponse<any, IErrorPayload>
    /* eslint-enable */
> {
    public response$: Subject<TResponse> = new Subject();
    public request$: Subject<TRequest> = new Subject();
    public errors$: Subject<IErrorPayload[]> = new Subject();

    private url = '';
    private method: HttpMethod = HttpMethod.GET;

    constructor(url: string, method: HttpMethod) {
        this.url = url;
        this.method = method;

        if (!url) throw new Error('URL has not been specified.');

        this.request$.subscribe(this.handleRequest.bind(this));
    }

    private async handleRequest(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        requestOptions?: TRequest
    ): Promise<void> {
        const options: RequestInit = {
            method: this.method,
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        };

        if (requestOptions && requestOptions.headers) {
            options.headers = {
                ...requestOptions.headers,
                ...options.headers
            };
        }

        let url = this.url;

        const { params } = requestOptions || {};
        switch (this.method) {
            case HttpMethod.POST:
            case HttpMethod.PUT:
                options.body =
                    JSON.stringify(requestOptions && requestOptions.body) ||
                    '{}';

                break;
            case HttpMethod.GET:
                url += params ? '?' + serialiseParams(params) : '';

                break;
        }

        const errors: IErrorPayload[] = [];

        let response: TResponse | null = null;
        let fetchResponse: Response;

        try {
            fetchResponse = await fetch(url, options);
            response = await fetchResponse.json();

            if (!fetchResponse.ok && response) {
                // Error status, use server-provided errors

                errors.push(...response.errors);
            }
        } catch (err) {
            // Invalid JSON or network issue
            errors.push({
                type: ErrorType.UNKNOWN_API_ERROR
            });
        }

        if (errors.length > 0) {
            this.errors$.next(errors);

            return;
        }

        if (response) this.response$.next(response);
    }
}

export default Service;
