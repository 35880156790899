import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate
} from '@azure/msal-react';
import { connect } from '@dazn/depo-react';
import {
    RmsButton,
    RmsFeedback
} from '@dazn/dx-rate-my-service-components-react';
import * as React from 'react';

import StoreName from '../../State/Shared/Constants/StoreName';
import IState from '../../State/Shared/Interfaces/IState';

const mapStateToProps = (value: IState) => ({
    [StoreName.AUTH]: value[StoreName.AUTH]
});

class Feedback extends React.Component<ReturnType<typeof mapStateToProps>> {
    constructor(props) {
        super(props);

        this.getToken = this.getToken.bind(this);
    }

    public render(): JSX.Element | null {
        return (
            <>
                <AuthenticatedTemplate>
                    <RmsFeedback
                        // fetchToken={this.getToken}
                        author={
                            this.props.auth.accountName ||
                            this.props.auth.accountUsername
                        }
                        displayName={'OpenAPI Hub'}
                        serviceName={'openapi-dashboard'}
                        endpointEnv={
                            process.env.REACT_APP_RMS_ENV as 'non-prod' | 'prod'
                        }
                    >
                        <RmsButton />
                    </RmsFeedback>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate></UnauthenticatedTemplate>
            </>
        );
    }

    private getToken = async () => this.props.auth.token;
}

export default connect(mapStateToProps)(Feedback);
