import IService from '@shared/Interfaces/Api/List/IListGetResponse';
import IResponse from '@shared/Interfaces/IResponse';

import formatOutputCase from '../../../Shared/Utils/formatOutputCase';

import Specification from './Constants/Specification';
import ApiSpecification from './Models/ApiSpecification';
import Dashboard from './Models/Dashboard';
import Service from './Models/Service';
import findService from './Util/findService';
import sortBySemVer from './Util/sortBySemver';

const toMap = (map, nextData) => {
    map[nextData.id] = formatOutputCase(nextData.id);

    return map;
};

const resolveDashboardActions = (
    value: Dashboard
): {
    loadDashboard: (rawData: IResponse<IService>) => void;
    selectService: (
        apiSpecificationId: Specification,
        serviceId: string,
        version: string,
        environment: string
    ) => void;
    setVersion: (version: string) => void;
    setSelectedService: (
        specification: Specification,
        service: string,
        version: string,
        environment: string
    ) => void;
} => ({
    loadDashboard(rawData: IResponse<IService>): void {
        const apiSpecifications = {};

        const services = Array.isArray(rawData.data)
            ? rawData.data
            : [rawData.data];

        services.map((service) => {
            Object.keys(service)
                .filter((key) => typeof service[key] === 'object')
                .map((apiSpecificationId) => {
                    if (!apiSpecifications[apiSpecificationId]) {
                        apiSpecifications[apiSpecificationId] = [];
                    }

                    const versions = sortBySemVer(
                        service[apiSpecificationId].versions
                    );
                    const serviceApiSpecification = Object.assign(
                        new Service(),
                        {
                            serviceId: service.id,
                            environment: service.environment,
                            versions,
                            version: versions[versions.length - 1] // last version
                        }
                    );

                    apiSpecifications[apiSpecificationId].push(
                        serviceApiSpecification
                    );
                });
        });

        value.apiSpecifications = Object.keys(apiSpecifications).map(
            (apiSpecificationId) => {
                return Object.assign(new ApiSpecification(), {
                    apiSpecificationId,
                    servicesList: apiSpecifications[apiSpecificationId]
                });
            }
        );

        value.services = services.reduce(toMap, {});
    },

    selectService(
        apiSpecificationId: Specification,
        serviceId: string,
        version: string,
        environment: string
    ): void {
        const service = findService(
            value.apiSpecifications,
            apiSpecificationId,
            serviceId
        );

        value.apiSpecifications.forEach((apiSpec) => {
            apiSpec.servicesList.forEach((currentService) => {
                currentService.isSelected = false;
            });
        });

        service.isSelected = !service.isSelected;

        this.setSelectedService(
            apiSpecificationId,
            serviceId,
            version,
            environment
        );
    },

    setVersion(version: string): void {
        if (!version) return;

        this.setSelectedService(
            value.selectedService.specification,
            value.selectedService.service,
            version,
            value.selectedService.environment
        );
    },

    setSelectedService(
        specification: Specification,
        service: string,
        version: string,
        environment: string
    ): void {
        value.selectedService = {
            specification,
            service,
            version,
            environment
        };
    }
});

export default resolveDashboardActions;
