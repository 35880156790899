import { connect } from '@dazn/depo-react';
import * as React from 'react';

import StoreName from '../../State/Shared/Constants/StoreName';
import IState from '../../State/Shared/Interfaces/IState';
import composeTokenList from '../Util/composeTokenList';

import * as styles from './servicesList.css';

const mapStateToProps = (value: IState) => ({
    dashboard: value[StoreName.DASHBOARD]
});

class ServicesList extends React.Component<ReturnType<typeof mapStateToProps>> {
    constructor(props) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <aside className={styles.platformMenu}>
                <header className={styles.platformMenuHeader} />
                {this.renderServicesList()}
            </aside>
        );
    }

    private renderServicesList(): JSX.Element[] {
        const { dashboard } = this.props;

        return Object.keys(dashboard.services).map((service) => {
            const platformMenuItemClassName = composeTokenList([
                styles.platformMenuItem
            ]);

            return (
                <div className={platformMenuItemClassName} key={service}>
                    {dashboard.services[service]}
                </div>
            );
        });
    }
}

export default connect(mapStateToProps)(ServicesList);
