import { Subscription } from 'rxjs';

import IApp from '../App/Interfaces/IApp';

import connectListSubscriptions from './List/connectListSubscriptions';
import connectSpecificationSubscriptions from './Specification/connectSpecificationSubscriptions';

const connectApiSubscriptions = (app: IApp): Subscription[] => [
    ...connectSpecificationSubscriptions(app),
    ...connectListSubscriptions(app)
];

export default connectApiSubscriptions;
