import { State, Store } from '@dazn/depo';
import { BehaviorSubject } from 'rxjs';

import StoreName from '../../Shared/Constants/StoreName';

import Auth from './Models/Auth';
import resolveAuthActions from './resolveAuthActions';

const createAuthStore = (
    state: State
): Store<Auth, typeof resolveAuthActions, BehaviorSubject<Auth>> =>
    state.createStore({
        name: StoreName.AUTH,
        actions: resolveAuthActions,
        model: Auth,
        observable: BehaviorSubject
    });

export default createAuthStore;
