import IListGetRequest from '@shared/Interfaces/Api/List/IListGetRequest';
import { Observable, Subject, Subscription } from 'rxjs';

const subscribeToDefaultEnvironment = (
    environment: string,
    authJwtUpdate$: Observable<string>,
    getListRequest$: Subject<IListGetRequest>
): Subscription =>
    authJwtUpdate$.subscribe((jwt) => {
        return getListRequest$.next({
            headers: {
                Authorization: `Bearer ${jwt}`
            },
            params: {
                environment
            }
        });
    });

export default subscribeToDefaultEnvironment;
