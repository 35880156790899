import { Observable, Subscription } from 'rxjs';

import RootStatus from '../Constants/RootStatus';
import createRootStore from '../createRootStore';

const subscribeToLoadingStream = (
    loading$: Observable<boolean>,
    rootStore: ReturnType<typeof createRootStore>
): Subscription =>
    loading$.subscribe((isLoading) => {
        const status = isLoading ? RootStatus.LOADING : RootStatus.LOADED;
        rootStore.actions.setStatus(status);
    });

export default subscribeToLoadingStream;
