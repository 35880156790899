import Specification from '../Constants/Specification';

class SelectedService {
    public specification: Specification = Specification.NONE;
    public service = '';
    public version = '';
    public environment = '';
}

export default SelectedService;
