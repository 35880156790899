import { connect } from '@dazn/depo-react';
import * as React from 'react';

import StoreName from '../../State/Shared/Constants/StoreName';
import IState from '../../State/Shared/Interfaces/IState';
import composeTokenList from '../Util/composeTokenList';

import ILoadingShieldProps from './Interfaces/ILoadingShieldProps';
import * as styles from './loading-shield.css';

const mapStateToProps = (value: IState) => ({
    root: value[StoreName.ROOT]
});

const LoadingShield = (props: ILoadingShieldProps): JSX.Element => {
    const loadingShieldClassName = composeTokenList([
        styles.loadingShield,
        [props.root.isLoading, styles.loadingShieldVisible]
    ]);

    return <div className={loadingShieldClassName} />;
};

export default connect(mapStateToProps)(LoadingShield);
