import Environment from '@shared/Constants/Environment';
import ErrorType from '@shared/Constants/ErrorType';

import config from '../../../../config';
import IServiceUrlMap from '../../Config/Interfaces/IServiceUrlMap';
import RootStatus from '../Constants/RootStatus';

class Root {
    public status: RootStatus = RootStatus.UNLOADED;
    public Environment: Environment = Environment.DEVELOPMENT;
    public errorType: ErrorType = ErrorType.NONE;
    public serviceUrls: IServiceUrlMap = {
        specification: config.endpoints.specification,
        list: config.endpoints.list
    };

    public get isLoading(): boolean {
        return this.status === RootStatus.LOADING;
    }
}

export default Root;
