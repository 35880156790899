import * as React from 'react';
import { Subject } from 'rxjs';

import Feedback from '../../Components/Feedback';
import Header from '../../Components/Header';
import LoadingShield from '../../Components/LoadingShield';
import SelectionBar from '../../Components/SelectionBar';
import ServicesGrid from '../../Components/ServicesGrid';
import ServicesList from '../../Components/ServicesList';
import IUiEventStreamPayload from '../../Shared/Interfaces/UiEventStreamPayload';

import * as styles from './dashboard.css';

interface IRootProps {
    uiEvents$: Subject<IUiEventStreamPayload>;
}

const DashboardPage = (props: IRootProps): JSX.Element => {
    return (
        <div className={styles.root}>
            <Header />
            <main className={styles.main}>
                <ServicesList />
                <ServicesGrid />
                <LoadingShield />
                <SelectionBar uiEvents$={props.uiEvents$} />
            </main>
            <Feedback />
        </div>
    );
};

export default DashboardPage;
