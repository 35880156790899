import { State, Store } from '@dazn/depo';
import { BehaviorSubject } from 'rxjs';

import StoreName from '../../Shared/Constants/StoreName';

import Dashboard from './Models/Dashboard';
import resolveDashboardActions from './resolveDashboardActions';

const createDashboardStore = (
    state: State
): Store<
    Dashboard,
    typeof resolveDashboardActions,
    BehaviorSubject<Dashboard>
> =>
    state.createStore({
        name: StoreName.DASHBOARD,
        actions: resolveDashboardActions,
        model: Dashboard,
        observable: BehaviorSubject
    });

export default createDashboardStore;
