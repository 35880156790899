import ISpecification from '@shared/Interfaces/Api/Specification/ISpecificationGetResponse';
import IResponse from '@shared/Interfaces/IResponse';
import { Observable, Subscription } from 'rxjs';

const subscribeToSpecificationResponse = (
    specificationResponse$: Observable<IResponse<ISpecification>>,
    setSpecification: (response: IResponse<ISpecification>) => void
): Subscription =>
    specificationResponse$.subscribe((response) => {
        setSpecification(response);
    });

export default subscribeToSpecificationResponse;
