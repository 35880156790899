const config = {
    endpoints: {
        specification: process.env.ENDPOINT_SPECIFICATION || '',
        list: process.env.ENDPOINT_LIST || ''
    },
    msal: {
        auth: {
            clientId: process.env.AAD_CLIENT_ID || '',
            authority: `https://login.microsoftonline.com/${process.env.AAD_TENANT_ID}/`,
            redirectUri: '/'
        },
        cache: {
            cacheLocation: 'sessionStorage', // This configures where your cache will be stored
            storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
        }
    }
};

export default config;
