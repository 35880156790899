// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".specification___main___6dUkw {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n}\n", ""]);
// Exports
exports.locals = {
	"main": "specification___main___6dUkw"
};
module.exports = exports;
