import { Subscription } from 'rxjs';

import IApp from '../App/Interfaces/IApp';

import connectDashboardSubscriptions from './Stores/Dashboard/connectDashboardSubscriptions';
import connectRootSubscriptions from './Stores/Root/connectRootSubscriptions';

const connectStoreSubscriptions = (app: IApp): Subscription[] => [
    ...connectDashboardSubscriptions(app),
    ...connectRootSubscriptions(app)
];

export default connectStoreSubscriptions;
