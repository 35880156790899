import ApiSpecification from '../Models/ApiSpecification';
import Service from '../Models/Service';

import findApiSpec from './findApiSpec';

const findService = (
    apiSepecifications: ApiSpecification[],
    apiSpecificationId: string,
    serviceId: string
): Service => {
    const apiSpec = findApiSpec(apiSepecifications, apiSpecificationId);

    const matchService = apiSpec.servicesList.find(
        (service) => service.serviceId === serviceId
    );

    if (!matchService) throw new Error(`Service "${serviceId}" not found`);

    return matchService;
};

export default findService;
