import IListGetRequest from '@shared/Interfaces/Api/List/IListGetRequest';
import { Observable, Subject, Subscription } from 'rxjs';

const subscribeToEnvironmentChange = (
    environmentAndJwtChange$: Observable<[string, string]>,
    getListRequest$: Subject<IListGetRequest>
): Subscription =>
    environmentAndJwtChange$.subscribe(([environment, jwt]) =>
        getListRequest$.next({
            headers: {
                Authorization: `Bearer ${jwt}`
            },
            params: {
                environment
            }
        })
    );

export default subscribeToEnvironmentChange;
