import ILabelMap from '../../../Shared/Interfaces/ILabelMap';

import ApiSpecification from './ApiSpecification';
import SelectedService from './SelectedService';

class Dashboard {
    public apiSpecifications: ApiSpecification[] = [];
    public services: ILabelMap = {};
    public selectedService: SelectedService = new SelectedService();

    public get hasSelected(): boolean {
        return Boolean(
            this.apiSpecifications.find((region) => region.hasSelected)
        );
    }

    public get serviceVersions(): Array<{ value: string; label: string }> {
        const service = this.apiSpecifications
            .filter(
                ({ apiSpecificationId }) =>
                    apiSpecificationId === this.selectedService.specification
            )
            .map(
                (apiSpecification) =>
                    apiSpecification.servicesList.filter(
                        ({ serviceId }) =>
                            serviceId === this.selectedService.service
                    )[0]
            )[0];

        if (!service) return [];

        return service.versions.map((version) => ({
            value: version,
            label: version
        }));
    }
}

export default Dashboard;
