import { AccountInfo } from '@azure/msal-browser';
import Auth from './Models/Auth';

const resolveAuthActions = (
    value: Auth
): {
    setToken: (token: string) => void;
    setAccount: (account?: AccountInfo) => void;
} => ({
    setToken(token: string): void {
        value.token = token;
    },
    setAccount(account?: AccountInfo): void {
        value.accountName = account?.name || '';
        value.accountUserName = account?.username || '';
    }
});

export default resolveAuthActions;
