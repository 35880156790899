import { connect } from '@dazn/depo-react';
import Specification from 'dashboard/src/State/Stores/Dashboard/Constants/Specification';
import * as React from 'react';

import StoreName from '../../State/Shared/Constants/StoreName';
import Service from '../../State/Stores/Dashboard/Models/Service';
import composeTokenList from '../Util/composeTokenList';

import * as styles from './servicesGrid.css';

const mapStateToProps = (value, actions) => ({
    dashboard: value[StoreName.DASHBOARD],
    config: value[StoreName.CONFIG],
    root: value[StoreName.ROOT],
    selectServiceAction: actions[StoreName.DASHBOARD].selectService
});

type IProps = ReturnType<typeof mapStateToProps>;
type IServicesGrid = IProps & {
    onDidUpdate?: () => void;
};

class ServicesGrid extends React.Component<IServicesGrid> {
    public shouldComponentUpdate(nextProps: IServicesGrid): boolean {
        return (
            nextProps.root.isLoading !== this.props.root.isLoading ||
            nextProps.dashboard !== this.props.dashboard
        );
    }

    public render(): JSX.Element {
        return <>{this.renderRegionsList()}</>;
    }

    public componentDidUpdate(): void {
        if (typeof this.props.onDidUpdate !== 'function') return;

        this.props.onDidUpdate();
    }

    private renderServices(
        specification: Specification,
        servicesList: Service[]
    ): JSX.Element[] {
        return Object.keys(this.props.dashboard.services).map((service) => {
            const apiIndex = servicesList.findIndex(
                (indexedApi) => indexedApi.serviceId === service
            );
            const currentService = servicesList[apiIndex];
            const NA = '-';

            let serviceId = '';
            let isSelected = false;
            let version = NA;
            let environment = '';
            if (apiIndex > -1) {
                serviceId = currentService.serviceId;
                isSelected = currentService.isSelected;
                version = currentService.version || version;
                environment = currentService.environment;
            }

            const className = composeTokenList([
                styles.platformDeployment,
                [isSelected, styles.platformDeploymentPrimed],
                [Boolean(version === NA), styles.platformDeploymentDisabled]
            ]);

            return (
                <div
                    key={`${specification}-${service}-${version}`}
                    className={className}
                    onClick={this.handleServiceClick.bind(
                        this,
                        specification,
                        serviceId,
                        version,
                        environment
                    )}
                >
                    {version}
                </div>
            );
        });
    }

    private renderImage(apiSpec: string): JSX.Element {
        const imageStyles: React.CSSProperties = {
            backgroundImage: `url('/assets/logos/${apiSpec}.png')`
        };

        return (
            <div>
                <div
                    className={styles.regionalDeploymentListHeaderFlag}
                    style={imageStyles}
                />
                <div>{apiSpec}</div>
            </div>
        );
    }

    private renderRegionsList(): JSX.Element[] {
        const { dashboard } = this.props;

        return dashboard.apiSpecifications.map(
            ({ apiSpecificationId, servicesList, isSelected }) => {
                const regionalDeploymentListClassName = composeTokenList([
                    styles.regionalDeploymentList,
                    [isSelected, styles.regionalDeploymentListPrimed]
                ]);

                return (
                    <div
                        className={regionalDeploymentListClassName}
                        key={apiSpecificationId}
                    >
                        <header className={styles.regionalDeploymentListHeader}>
                            {this.renderImage(apiSpecificationId)}
                        </header>
                        {this.renderServices(apiSpecificationId, servicesList)}
                    </div>
                );
            }
        );
    }

    private handleServiceClick(
        apiSpecification: Specification,
        serviceId: string,
        version: string,
        environment: string
    ): void {
        this.props.selectServiceAction(
            apiSpecification,
            serviceId,
            version,
            environment
        );
    }
}

export default connect(mapStateToProps)(ServicesGrid);
